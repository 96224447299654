import React from 'react';

class IconDefault extends React.Component {
    render() {
        return (
            <svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Group 47</title>
                <desc>Created with Sketch.</desc>
                <g id="specs" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="dictionary_Graph" transform="translate(-316.000000, -6106.000000)" fill-rule="nonzero">
                        <g id="Group-47" transform="translate(316.000000, 6106.000000)">
                            <g id="icon_administrative" fill="#9B9B9B">
                                <path d="M10,0.498854962 C15.5225192,0.498854962 20,4.97633579 20,10.498855 C20,16.0207313 15.5225192,20.498855 10,20.498855 C4.47748082,20.498855 0,16.0213027 0,10.498855 C0,4.97640721 4.47683803,0.498854962 10,0.498854962 Z" id="Shape"></path>
                            </g>
                            <g id="Group-46" transform="translate(4.000000, 9.500000)" fill="#FFFFFF">
                                <rect id="Rectangle-3" x="0" y="0" width="5" height="2" rx="0.5"></rect>
                                <path d="M7.5,0 L11.5,0 C11.7761424,-5.07265313e-17 12,0.223857625 12,0.5 L12,1.5 C12,1.77614237 11.7761424,2 11.5,2 L7.5,2 C7.22385763,2 7,1.77614237 7,1.5 L7,0.5 C7,0.223857625 7.22385763,5.07265313e-17 7.5,0 Z" id="Rectangle-3"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>            
        )
    }
}

export default IconDefault;