import React from 'react';

class IconClinical extends React.Component {
    render() {
        return (
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>hospital</title>
                <desc>Created with Sketch.</desc>
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="icon_clinical" fill-rule="nonzero">
                        <g id="hospital">
                            <path d="M10,0 C15.5225192,0 20,4.47748082 20,10 C20,15.5218764 15.5225192,20 10,20 C4.47748082,20 0,15.5224478 0,10 C0,4.47755224 4.47683803,0 10,0 Z" id="Shape" fill="#05B8EE"></path>
                            <path d="M5.45055698,8.05299009 L8.05984005,8.05299009 L8.05984005,5.45009286 C8.05984005,5.09128261 8.35036275,4.8 8.70906027,4.8 L11.3059412,4.8 C11.6640446,4.8 11.954493,5.09120832 11.954493,5.45009286 L11.954493,8.05239578 L14.5501114,8.05239578 C14.9094773,8.05239578 15.2,8.3430098 15.2,8.70248864 L15.2,11.3034544 C15.2,11.6636018 14.9094773,11.9548101 14.5501114,11.9548101 L11.954493,11.9548101 L11.954493,14.5499071 C11.954493,14.909386 11.6639703,15.2 11.3059412,15.2 L8.70906027,15.2 C8.35036275,15.2 8.05984005,14.909386 8.05984005,14.5499071 L8.05984005,11.9548101 L5.45055698,11.9548101 C5.09119109,11.9548101 4.8,11.6636018 4.8,11.3034544 L4.8,8.70248864 C4.8,8.3436041 5.09119109,8.05299009 5.45055698,8.05299009 Z" id="Shape" fill="#FFFFFF"></path>
                        </g>
                    </g>
                </g>
            </svg>           
        )
    }
}

export default IconClinical;