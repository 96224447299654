import React from 'react';

class IconMetadata extends React.Component {
    render() {
        const fill = this.props.fill ? this.props.fill : "#F4B940";
        return (
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>icon_metadata</title>
                <desc>Created with Sketch.</desc>
                <g id="icon_metadata" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M10,0 C15.5225192,0 20,4.47748082 20,10 C20,15.5218764 15.5225192,20 10,20 C4.47748082,20 0,15.5224478 0,10 C0,4.47755224 4.47683803,0 10,0 Z" id="Shape" fill={fill} fill-rule="nonzero"></path>
                    <g id="Group-9" transform="translate(4.500000, 6.000000)" fill="#FFFFFF" fill-rule="nonzero">
                        <rect id="Rectangle-3" x="0" y="0" width="5" height="2" rx="0.5"></rect>
                        <path d="M6.5,0 L10.5,0 C10.7761424,-5.07265313e-17 11,0.223857625 11,0.5 L11,1.5 C11,1.77614237 10.7761424,2 10.5,2 L6.5,2 C6.22385763,2 6,1.77614237 6,1.5 L6,0.5 C6,0.223857625 6.22385763,5.07265313e-17 6.5,0 Z" id="Rectangle-3"></path>
                        <rect id="Rectangle-3" x="0" y="3" width="5" height="2" rx="0.5"></rect>
                        <path d="M6.5,3 L10.5,3 C10.7761424,3 11,3.22385763 11,3.5 L11,4.5 C11,4.77614237 10.7761424,5 10.5,5 L6.5,5 C6.22385763,5 6,4.77614237 6,4.5 L6,3.5 C6,3.22385763 6.22385763,3 6.5,3 Z" id="Rectangle-3"></path>
                        <rect id="Rectangle-3" x="0" y="6" width="5" height="2" rx="0.5"></rect>
                        <path d="M6.5,6 L10.5,6 C10.7761424,6 11,6.22385763 11,6.5 L11,7.5 C11,7.77614237 10.7761424,8 10.5,8 L6.5,8 C6.22385763,8 6,7.77614237 6,7.5 L6,6.5 C6,6.22385763 6.22385763,6 6.5,6 Z" id="Rectangle-3"></path>
                    </g>
                </g>
            </svg>            
        )
    }
}

export default IconMetadata;