import React from 'react';

class IconAnalysis extends React.Component {
    render() {
        return (
            <svg width="20px" height="21px" viewBox="0 0 20 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>icon_analysis</title>
                <desc>Created with Sketch.</desc>
                <g id="icon_analysis" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M10,0.498854962 C15.5225192,0.498854962 20,4.97633579 20,10.498855 C20,16.0207313 15.5225192,20.498855 10,20.498855 C4.47748082,20.498855 0,16.0213027 0,10.498855 C0,4.97640721 4.47683803,0.498854962 10,0.498854962 Z" id="Shape" fill="#FF7ABC" fill-rule="nonzero"></path>
                    <g id="Group-9" transform="translate(10.000000, 10.500000) rotate(-90.000000) translate(-10.000000, -10.500000) translate(5.500000, 5.500000)" fill="#FFFFFF" fill-rule="nonzero">
                        <rect id="Rectangle-3" x="0" y="0" width="4.5" height="2.7" rx="0.5"></rect>
                        <rect id="Rectangle-3" x="0" y="3.6" width="7.2" height="2.7" rx="0.5"></rect>
                        <path d="M0.5,7.2 L8.5,7.2 C8.77614237,7.2 9,7.42385763 9,7.7 L9,9.4 C9,9.67614237 8.77614237,9.9 8.5,9.9 L0.5,9.9 C0.223857625,9.9 3.38176876e-17,9.67614237 0,9.4 L0,7.7 C-3.38176876e-17,7.42385763 0.223857625,7.2 0.5,7.2 Z" id="Rectangle-3"></path>
                    </g>
                </g>
            </svg>            
        )
    }
}

export default IconAnalysis;