import React from 'react';

class IconIndexFile extends React.Component {
    render() {
        return (
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>Group</title>
                <desc>Created with Sketch.</desc>
                <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group">
                        <circle id="Oval" fill="#26D9B1" cx="10" cy="10" r="10"></circle>
                        <path d="M4.5,5.5 L7.5,5.5 C8.05228475,5.5 8.5,5.94771525 8.5,6.5 L8.5,6.5 C8.5,7.05228475 8.05228475,7.5 7.5,7.5 L4.5,7.5 C3.94771525,7.5 3.5,7.05228475 3.5,6.5 L3.5,6.5 C3.5,5.94771525 3.94771525,5.5 4.5,5.5 Z" id="Rectangle" fill="#FFFFFF"></path>
                        <path d="M5.5,8.5 L8.5,8.5 C9.05228475,8.5 9.5,8.94771525 9.5,9.5 L9.5,9.5 C9.5,10.0522847 9.05228475,10.5 8.5,10.5 L5.5,10.5 C4.94771525,10.5 4.5,10.0522847 4.5,9.5 L4.5,9.5 C4.5,8.94771525 4.94771525,8.5 5.5,8.5 Z" id="Rectangle-Copy-2" fill="#FFFFFF"></path>
                        <path d="M11.5,8.5 L14.5,8.5 C15.0522847,8.5 15.5,8.94771525 15.5,9.5 L15.5,9.5 C15.5,10.0522847 15.0522847,10.5 14.5,10.5 L11.5,10.5 C10.9477153,10.5 10.5,10.0522847 10.5,9.5 L10.5,9.5 C10.5,8.94771525 10.9477153,8.5 11.5,8.5 Z" id="Rectangle-Copy-3" fill="#FFFFFF"></path>
                        <path d="M7,11.5 L10,11.5 C10.5522847,11.5 11,11.9477153 11,12.5 L11,12.5 C11,13.0522847 10.5522847,13.5 10,13.5 L7,13.5 C6.44771525,13.5 6,13.0522847 6,12.5 L6,12.5 C6,11.9477153 6.44771525,11.5 7,11.5 Z" id="Rectangle-Copy-4" fill="#FFFFFF"></path>
                        <path d="M13,11.5 L16,11.5 C16.5522847,11.5 17,11.9477153 17,12.5 L17,12.5 C17,13.0522847 16.5522847,13.5 16,13.5 L13,13.5 C12.4477153,13.5 12,13.0522847 12,12.5 L12,12.5 C12,11.9477153 12.4477153,11.5 13,11.5 Z" id="Rectangle-Copy-5" fill="#FFFFFF"></path>
                        <path d="M10.5,5.5 L13.5,5.5 C14.0522847,5.5 14.5,5.94771525 14.5,6.5 L14.5,6.5 C14.5,7.05228475 14.0522847,7.5 13.5,7.5 L10.5,7.5 C9.94771525,7.5 9.5,7.05228475 9.5,6.5 L9.5,6.5 C9.5,5.94771525 9.94771525,5.5 10.5,5.5 Z" id="Rectangle-Copy" fill="#FFFFFF"></path>
                    </g>
                </g>
            </svg>            
        )
    }
}

export default IconIndexFile;