import React from 'react';

class IconCase extends React.Component {
    render() {
        return (
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>icon-case</title>
                <desc>Created with Sketch.</desc>
                <g id="icon-case" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-4">
                        <path d="M10,0 C15.5225192,0 20,4.47748082 20,10 C20,15.5218764 15.5225192,20 10,20 C4.47748082,20 0,15.5224478 0,10 C0,4.47755224 4.47683803,0 10,0 Z" id="Shape" fill="#AD91FF" fill-rule="nonzero"></path>
                        <g id="Group-10" transform="translate(3.500000, 4.900000)" fill="#FFFFFF">
                            <path d="M2,0 L2.72071305,0 C2.73327336,0.00536195783 2.74573554,0.0148378316 2.75845939,0.015577412 C3.01898764,0.0305539149 3.27974486,0.0389666418 3.54001143,0.0599522354 C4.00572408,0.0974859403 4.46950688,0.161367197 4.92488344,0.312981177 C5.30241226,0.438617396 5.6556382,0.634606199 5.94328232,1.02025115 C5.98070157,1.07045017 6.00003267,1.11972471 5.99999996,1.19677974 C5.99839721,3.76210678 5.99869159,6.32743381 5.99869159,8.89276084 L5.99869159,9 C5.98083241,8.99329755 5.97379994,8.99237308 5.9679123,8.98816671 C5.88977018,8.93218972 5.81074491,8.87843147 5.73394387,8.81894148 C5.11891402,8.34237436 4.45675032,8.08731157 3.76135414,8.01497137 C3.25079727,7.96186025 2.73834327,7.94508102 2.22670701,7.91351018 C2.15605528,7.90916515 2.08517458,7.91290927 2.00683621,7.91290927 L2.00683621,7.76295935 C2.00683621,5.22051308 2.00686892,2.67806682 2.00654183,0.13557433 C2.00654183,0.090367479 2.00225693,0.0452068514 2,0" id="Fill-1"></path>
                            <path d="M10.2790529,0 L11,0 C10.9977096,0.0451599994 10.993456,0.0902738229 10.9934233,0.135433822 C10.9931289,2.6785236 10.9931943,5.22161338 10.992998,7.76470317 C10.9929653,7.80870877 10.9903477,7.85276055 10.9887771,7.90147609 C10.9576606,7.90147609 10.9347241,7.90133756 10.9117548,7.90152226 C10.3158953,7.90572427 9.72098471,7.93038218 9.12771007,8.0191322 C8.47073008,8.11734827 7.84699335,8.36388124 7.26464712,8.80915514 C7.18088454,8.87320107 7.09499518,8.93170589 7,9 L7.00000973,8.89333374 C7.00000973,6.3241546 6.99974797,3.75492927 7.00194019,1.1857963 C7.00197291,1.13495667 7.02160477,1.07141868 7.04728978,1.03544768 C7.19443052,0.829133895 7.36571845,0.670427435 7.55555848,0.552355739 C7.88606074,0.346826948 8.23632574,0.247872062 8.59120422,0.177361511 C9.09705166,0.0768366452 9.60630196,0.03721775 10.1165993,0.0187474026 C10.1708159,0.0167618403 10.2249017,0.00646462159 10.2790529,0" id="Fill-3"></path>
                            <path d="M0,1 L0.982511264,1 L0.982511264,9.11442041 C1.02922633,9.11442041 1.06639076,9.11383042 1.10355519,9.11451875 C1.71271965,9.12597444 2.32229935,9.12425363 2.93088246,9.15267163 C3.64834283,9.18615374 4.36501422,9.25793623 5.05212017,9.52667807 C5.36758182,9.65008495 5.66958953,9.82187126 5.97741067,9.97256532 C5.98654606,9.97703944 5.99252559,9.99060928 6,10 L0,10 L0,1 Z" id="Fill-5"></path>
                            <path d="M8,10 C8.00622868,9.99065844 8.01121162,9.97792443 8.01882444,9.97241782 C8.51902168,9.61207956 9.04562159,9.37377153 9.60263819,9.27022775 C10.1626307,9.1661923 10.7251839,9.1273511 11.2892943,9.1215495 C11.5554318,9.11884536 11.8215346,9.11678039 12.0876375,9.11446958 C12.1161856,9.11422375 12.1447337,9.11442041 12.1812406,9.11442041 L12.1812406,1 L13,1 L13,10 L8,10 Z" id="Fill-8"></path>
                        </g>
                    </g>
                </g>
            </svg>           
        )
    }
}

export default IconCase;