import React from 'react';

class IconBiospecimen extends React.Component {
    render() {
        return (
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>icon_Biospecimen</title>
                <desc>Created with Sketch.</desc>
                <g id="icon_Biospecimen" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-7" fill="#27AE60" fill-rule="nonzero">
                        <path d="M10,0 C15.5225192,0 20,4.47748082 20,10 C20,15.5218764 15.5225192,20 10,20 C4.47748082,20 0,15.5224478 0,10 C0,4.47755224 4.47683803,0 10,0 Z" id="Shape"></path>
                    </g>
                    <g id="Group-8" transform="translate(4.500000, 3.000000)" fill="#FFFFFF">
                        <path d="M7.03923527,5.80657865 C6.94854501,5.69588395 6.90867014,5.65758909 6.90942249,5.51741878 C6.91472034,4.57499025 6.91208709,3.55951728 6.91224383,2.61705946 C6.91233787,1.87970033 6.91102125,1.1423412 6.91371719,0.404982075 C6.91503382,0.0500734237 6.86584854,0.000146540127 6.48521904,0.000117240312 C5.86349077,8.79404979e-05 5.24176249,-0.000205057645 4.62003421,0.000263739384 C4.34150571,0.000468838084 4.25808823,0.079695536 4.25799418,0.343921262 C4.2576807,1.1424584 4.25780609,1.94099554 4.25783744,2.73953268 C4.26316663,3.53792332 4.26150518,5.17147587 4.25391892,5.51741878 C4.25206938,5.60221244 4.21721021,5.70083562 4.16241361,5.76807869 C2.84682491,7.38378765 1.53173779,8.99999471 0.201446798,10.6051264 C-0.0161722059,10.8677407 -0.0695581413,11.1064463 0.101007258,11.3919437 C0.107182847,11.4022865 0.362847558,11.9128997 0.557180629,11.9709666 C0.68673601,12.0096778 3.83578693,12.0096778 10.0043334,11.9709666 C10.2103654,11.9709666 10.6316993,11.8336839 10.8957178,11.5340604 C11.0032564,11.4120194 11.0978065,10.81899 10.8010935,10.4737836 C9.47986212,8.85558411 8.36338202,7.4226978 7.03923527,5.80657865 Z" id="Fill-1"></path>
                    </g>
                </g>
            </svg>            
        )
    }
}

export default IconBiospecimen;