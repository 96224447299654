import React from 'react';

class IconNotation extends React.Component {
    render() {
        return (
            <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <title>icon_notation</title>
                <desc>Created with Sketch.</desc>
                <g id="icon_notation" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Group-7" fill="#E74C3C" fill-rule="nonzero">
                        <path d="M10,0 C15.5225192,0 20,4.47748082 20,10 C20,15.5218764 15.5225192,20 10,20 C4.47748082,20 0,15.5224478 0,10 C0,4.47755224 4.47683803,0 10,0 Z" id="Shape"></path>
                    </g>
                    <path d="M2.5,10.2083333 L12.5,10.2083333" id="Line-11" stroke="#FFFFFF" stroke-linecap="square" fill-rule="nonzero"></path>
                    <path d="M16.5,5.5 L12.5,10.2083333" id="Line-11" stroke="#FFFFFF" stroke-linecap="square" fill-rule="nonzero"></path>
                    <path d="M16.5,15.5 L12.5,10.2083333" id="Line-11" stroke="#FFFFFF" stroke-linecap="square" fill-rule="nonzero"></path>
                    <path d="M7.25,16.5 L7.25,4.5" id="Line-11" stroke="#FFFFFF" stroke-linecap="square" fill-rule="nonzero"></path>
                </g>
            </svg>            
        )
    }
}

export default IconNotation;